import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userId: null,
    userName: null,
    userRole: null,
    userData: null,
    subscription: null,
    token: null,
  },
  reducers: {
    setAuthData(state, action) {
      const { userId, userName, userRole, userData, subscription, token } =
        action.payload;
      state.userId = userId;
      state.userName = userName;
      state.userRole = userRole;
      state.userData = userData;
      state.subscription = subscription;
      state.token = token;
    },
    clearAuthData(state) {
      state.userId = null;
      state.userName = null;
      state.userRole = null;
      state.userData = null;
      state.subscription = null;
      state.token = null;
    },
  },
});

export const { setAuthData, clearAuthData } = authSlice.actions;
export default authSlice.reducer;
